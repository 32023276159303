import ApproveEvent from "./ApproveEvent";
import {Divider} from "primereact/divider";
import React, {useRef} from "react";
import {Button} from "primereact/button";
import ReactToPrint from "react-to-print";


export default function PrintPanel(props) {
    const ref = useRef();

    return <div>
            <div ref={ref}>
                {props.children}
            </div>
            <div className="absolute right-0 bottom-0">
                <ReactToPrint bodyClass="print-agreement" 
                              content={() => ref.current} 
                              trigger={() => (<Button type="primary" icon="pi pi-print"></Button>
            )}/></div>
            
            </div>
}