import {ProgressSpinner} from "primereact/progressspinner";
import React from "react";
import PropTypes from "prop-types";
import FullyOverlaySpinnerCss from "./FullOverlaySpinner.css"


export default function FullOverlaySpinner ({show}){
    return show ? <div className="full-progress-spinner"><ProgressSpinner/></div> : null
}


FullOverlaySpinner.propTypes = {
    show: PropTypes.bool.isRequired,
};