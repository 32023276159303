const PATH = {
  NOPAGE: "*",

  //  AUTH PATHS
  ROOT: "/",
  DASHBOARD: "/dashboard",
  EVENTS: "/events",
  EVENTS_TYPES: "/events/types",
  
  
};

export default PATH;
 export const PAGES_INFO = [
  {
    pathName: PATH.DASHBOARD,
    title: "Calendar",
    icon: "pi-calendar", 
    pages: [],
    roles: [],
  },
 {
     title: "Manage Events",
     icon: "pi-pencil",
     pathName: PATH.EVENTS,
     roles: ["admin", "majlis", "national", "region", "aitmad", "maal"],
     pages: []
 },
 {
     title: "Manage Eventtypes",
     icon: "pi-pencil",
     pathName: PATH.EVENTS_TYPES,
     roles: ["admin", "aitmad"],
     pages: []
 }

];
