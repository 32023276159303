import {eventsClient} from "../../index";

const loadCalendarEvents = async (data) => {
  const response = await eventsClient.calendarApi().getAllCalendarEvents(data.page,data.size,null,data.region,data.majlis);
  return response.data;
};

const loadMajalis = async (data) => {
  const response = await eventsClient.calendarApi().getMajlises();
  return response.data;
};

const loadRegions = async (data) => {
  const response = await eventsClient.calendarApi().getRegions();
  return response.data;
};

const loadUnitsByParent = async (data) => {
  const response = await eventsClient.calendarApi().getTanzeemUnitsByParent(data.parentKey);
  return response.data;
};

const calendars = {
  loadCalendarEvents,
  loadMajalis,
  loadRegions,
  loadUnitsByParent
};

export default calendars;