import {eventsClient} from "../../index";

const createEvent = async (event) => {
  const response = await eventsClient.eventsApi().addEvent(event);
  return response.data;
};

// Get All list of projects
const loadEvents = async (data) => {
  const response = await eventsClient.eventsApi().getAllEvents(data.page,data.size);
  return response.data;
};

const updateEvent = async (event) => {
  const response = await eventsClient.eventsApi().updateEvent(event.id,event);
  return response.data;
};

const updateEventStatus = async (data) => {
  const response = await eventsClient.eventsApi().updateEventStatus(data.eventId,{...data.additionalData, status: data.status});
  return response.data;
};

const updateEventsStatus = async (data) => {
  const response = await eventsClient.eventsApi().updateEventsStatus({...data.additionalData, ids: data.ids,status: data.status});
  return response.data;
};

const addComment = async (data) => {
  const response = await eventsClient.eventsApi().addComment(data.eventId,data.additionalData);
  return response.data;
};

// Delete projects
const deleteEvents = async (data) => {
  const response = await eventsClient.eventsApi().deleteEvents(data);
  return response.data;
};

// Get Single projects
const getEventById = async (data) => {
  //const response = await eventsClient.eventsApi().get(data.eventId)
  return null;
};

const events = {
  addComment,
  loadEvents,
  createEvent,
  updateEvent,
  updateEventStatus,
  updateEventsStatus,
  getEventById,
  deleteEvents,
};

export default events;