import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import {Field, Form, FormSpy} from "react-final-form";
import {Calendar} from "primereact/calendar";
import {classNames} from 'primereact/utils';
import {Accordion, AccordionTab} from "primereact/accordion";
import {
    getCheckBox,
    getInputNumber,
    getInputText,
    getInputTextArea,
    getSelectionDropdown
} from "../../utils/inputHelper";
import {useDispatch, useSelector} from "react-redux";
import {loadTanzeemLevels, loadTanzeems} from "../../store/tanzeem/tanzeem.slice";
import {defaultErrorMessageToast} from "../../utils/toastHelper";
import {loadAllEventTypes} from "../../store/eventTypes/eventTypes.slice";
import {capitalizeString, prettifyLabel} from "../../utils/utils";
import {getUsersTanzeemUnitByLevel, getUsersTanzeemUnitByLevelCapitalized} from "../../utils/userHelper";
import {keycloak} from "../../app_layout/Keycloak";
import {Dropdown} from "primereact/dropdown";


export default function CreateUpdateEvent(props) {
    const dispatch = useDispatch();
    const {tanzeems, tanzeemLevels} = useSelector(state => state.tanzeem)
    const {allEventTypes} = useSelector(state => state.eventTypes)
    const [eventLevels , setEventLevels] = useState([]);
    const [visibleEventTypes , setVisibleEventTypes] = useState([]);
    const {event} = props
    const {toast} = useSelector(state => state.app);
    const toastErrorMessage = (response) => {
        defaultErrorMessageToast(toast, response)
    };

    const setVisibleEventTypesByTanzeemLevel = (tanzeemLevel) => {
        const visibleEventTypes = allEventTypes.content.filter(eventType => eventType.tanzeemLevel === tanzeemLevel)
        setVisibleEventTypes(visibleEventTypes);
    }

    const emptyValues = {}

    let initialValues = emptyValues;
    
    if (event) {
        initialValues = {
            ...emptyValues,
            eventType: event.eventType,
            tanzeemUnit: null,
            location: event.location,
            startDateTime: event.startDateTime,
            endDateTime: event.endDateTime,
            agenda: event.agenda,
            hospitality: event.expenseDetails.hospitality,
            rent: event.expenseDetails.rent,
            others: event.expenseDetails.others,
            numberOfMenus: event.expenseDetails.numberOfMenus,
            menus: event.expenseDetails.menus,
            estimateParticipants: event.expenseDetails.estimateParticipants,
            selfInvolvement: event.expenseDetails.selfInvolvement,
            visitRequested: event.visitRequested,
            customTitle: event.customTitle,
            budgetComment: event.expenseDetails.budgetComment
        }
    }


    useEffect(() => {
        if (!tanzeems || (tanzeems.length && tanzeems.length === 0)) {
            dispatch(loadTanzeems({finalData: {}, callbackError: toastErrorMessage}))
        }

        if (!tanzeemLevels || (tanzeemLevels.length && tanzeemLevels.length === 0)) {
            dispatch(loadTanzeemLevels({finalData: {}, callbackError: toastErrorMessage}))
        }
        dispatch(loadAllEventTypes({callbackError: toastErrorMessage}))
    }, [])

    useEffect(() => {
        if(allEventTypes?.content != null){
            if (event) {
                setVisibleEventTypesByTanzeemLevel(event.eventType.tanzeemLevel)
            }
            else{
                const eventLevels = allEventTypes.content.map(eventType => eventType.tanzeemLevel).filter((value, index, array) => array.indexOf(value) === index);
                console.log(eventLevels);
                if(eventLevels.length === 1)
                {
                    setVisibleEventTypes(allEventTypes.content);
                }else{

                    setEventLevels(eventLevels.map(eventLevel => { return {label: prettifyLabel(eventLevel), value: eventLevel} }));
                }    
            }
        }
    }, [allEventTypes])


    const validate = (data) => {
        let errors = {};
        
        const requiredFields = ["eventType", "tanzeemUnit", "startDateTime","endDateTime", "location"]
        
        if(data && data.eventType)
        {
            if (data?.eventType?.budgetRequired === "required")
            {
                requiredFields.push("estimateParticipants")
                requiredFields.push("numberOfMenus")
                requiredFields.push("menus")

                if (!data["hospitality"] && !data["rent"] && !data["others"]) {
                    errors["hospitality"] = 'One Head Field is required.';
                    errors["rent"] = 'One Head Field is required.';
                    errors["others"] = 'One Head Field is required.';
                }
            }

            if (data?.eventType?.budgetSelfInvolvementRequired  === "required")
            {
                requiredFields.push("selfInvolvement")
            }

            if (data?.eventType?.agendaRequired  === "required")
            {
                requiredFields.push("agenda")
            }

            if (data?.eventType?.customTitleSettable)
            {
                requiredFields.push("customTitle")
            }
        }

        requiredFields.forEach(requiredField => {
            if (!data[requiredField]) {
                errors[requiredField] = 'Field is required.';
            }
        })
        return errors;
    };

    const onSubmit = (data, form) => {
        form.restart();
        props.onSubmit(data)
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const getRelevantUnit = (eventType) => {
        return <div style={{marginBottom: "2rem", color: "green"}}>Programm
            für {capitalizeString(eventType.tanzeemLevel) + ": " + getUsersTanzeemUnitByLevelCapitalized(keycloak, eventType.tanzeemLevel)}</div>
    }
    
    return (
        <div className="form-demo">
            <div className="flex justify-content-center">
                <div className="card">
                    {visibleEventTypes && visibleEventTypes.length > 0 ? 
                        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}
                          render={({handleSubmit, values}) => {
                              return <form onSubmit={handleSubmit} className="p-fluid">
                                  <FormSpy subscription={{values: true}}>
                                      {({values}) => {
                                          const hintMessage = !values.eventType ? <span style={{color: "green"}}>Bitte erst ein Programm auswählen!</span> : ""
                                          const tanzeemUnitMessage = values.eventType && values?.eventType?.tanzeemLevel ? getRelevantUnit(values.eventType) : ""
                                          values.tanzeemUnit = values.eventType ? getUsersTanzeemUnitByLevel(keycloak, values?.eventType?.tanzeemLevel) : null
                                          console.log(values)
                                          console.log(values?.eventType)
                                          return <React.Fragment>
                                              {hintMessage}
                                              {getSelectionDropdown("eventType", "Programm", visibleEventTypes, "label", isFormFieldValid, getFormErrorMessage)}
                                              {tanzeemUnitMessage}
                                              {values?.eventType?.customTitleSettable ? getInputText("customTitle", "Titel der Veranstaltung", isFormFieldValid, getFormErrorMessage, !values.eventType) : ""}
                                              {getInputText("location", "Ort", isFormFieldValid, getFormErrorMessage, !values.eventType)}
                                              <div className="formgrid grid">
                                                  <Field name="startDateTime" render={({input, meta}) => (
                                                      <div className="field col-6">
                                                        <span className="p-float-label">
                                                            <Calendar disabled={!values.eventType} id="startDateTime"  {...input}
                                                                      value={values.startDateTime ? new Date(values.startDateTime) : null}
                                                                      dateFormat="dd.mm.yy" showTime hourFormat="24" showIcon
                                                                      onChange={(newValue) => {
                                                                          if (newValue === null) {
                                                                              input.onChange(null);
                                                                          } else {
                                                                              input.onChange(newValue);
                                                                          }
                                                                      }}/>
                                                            <label htmlFor="startDateTime" className={classNames({'p-error': isFormFieldValid(meta)})}>Beginn</label>
                                                        </span>
                                                          {getFormErrorMessage(meta)}
                                                      </div>
                                                  )}/>
                                                  <Field name="endDateTime" render={({input, meta}) => (
                                                      <div className="field col-6">
                                                    <span className="p-float-label">
                                                        <Calendar disabled={!values.eventType} id="endDateTime"  {...input}
                                                                  dateFormat="dd.mm.yy" showTime hourFormat="24" showIcon
                                                                  value={values.endDateTime ? new Date(values.endDateTime) : null}
                                                                  onChange={(newValue) => {
                                                                      if (newValue === null) {
                                                                          input.onChange(null);
                                                                      } else {
                                                                          input.onChange(newValue);
                                                                      }
                                                                  }}/>
                                                        <label htmlFor="endDateTime" className={classNames({'p-error': isFormFieldValid(meta)})}>Ende</label>
                                                    </span>
                                                          {getFormErrorMessage(meta)}
                                                      </div>
                                                  )}/>
                                                  </div>

                                              {values?.eventType?.visitRequestable ? getCheckBox("visitRequested", "Besuch aus der Zentrale erwünscht?", isFormFieldValid, getFormErrorMessage, !values.eventType) : ""}
                                              
                                              <Accordion multiple activeIndex={[]}>
                                                  {values?.eventType?.agendaRequired === "required" || values?.eventType?.agendaRequired === "optional" ?
                                                      <AccordionTab header="Agenda">
                                                          {getInputTextArea("agenda", "Programmablauf", isFormFieldValid, getFormErrorMessage, !values.eventType, 10)}
                                                      </AccordionTab> : ""
                                                  }

                                                  {values?.eventType?.budgetRequired === "required" || values?.eventType?.budgetRequired === "optional" || values?.eventType?.budgetSelfInvolvementRequired === "required" || values?.eventType?.budgetSelfInvolvementRequired === "optional"?
                                                      <AccordionTab header="Budget">
                                                          {values?.eventType?.budgetRequired === "required" || values?.eventType?.budgetRequired === "optional"?
                                                              <React.Fragment>
                                                                  <div className="formgrid grid">
                                                                      <div className="col-4">{getInputNumber("hospitality", "Verpflegung", isFormFieldValid, getFormErrorMessage, !values.eventType, "€")}</div>
                                                                      <div className="col-4">{getInputNumber("rent", "Miete", isFormFieldValid, getFormErrorMessage, !values.eventType,"€")}</div>
                                                                      <div className="col-4">{getInputNumber("others", "Sonstige", isFormFieldValid, getFormErrorMessage, !values.eventType,"€")}</div>
                                                                  </div>
                                                                  {getInputTextArea("budgetComment", "Bemerkung zum Budget", isFormFieldValid, getFormErrorMessage, !values.eventType, 3)}
                                                                  
                                                                  <div className="formgrid grid">
                                                                      <div className="col-6">{getInputText("estimateParticipants", "Erw. Teilnehmer", isFormFieldValid, getFormErrorMessage, !values.eventType)}</div>
                                                                      <div className="col-6">{getInputText("numberOfMenus", "Anzahl Mahlzeiten", isFormFieldValid, getFormErrorMessage, !values.eventType)}</div>
                                                                  </div>
                                                                  
                                                                  {getInputTextArea("menus", "Speisen Menüs", isFormFieldValid, getFormErrorMessage, !values.eventType, 5)}
                                                              </React.Fragment>: ""
                                                          }
                                                          {values?.eventType?.budgetSelfInvolvementRequired === "required" || values?.eventType?.budgetSelfInvolvementRequired === "optional"?
                                                              <React.Fragment>
                                                                {getInputNumber("selfInvolvement", "Selbstbeteiligung", isFormFieldValid, getFormErrorMessage, !values.eventType, "€")}
                                                              </React.Fragment>: ""
                                                          }
                                                      </AccordionTab>: ""
                                                  }
                                                  {values?.eventType?.flyerTemplateAvailable ?
                                                  <AccordionTab header="Flyer Daten">
                                                      Coming soon!
                                                  </AccordionTab> : ""
                                                  }
                                              </Accordion>
                                          </React.Fragment>
                                      }}
                                  </FormSpy>
                                  <Button type="submit" label="Submit" className="mt-2"/>
                              </form>
                          }}/> : 
                        
                              <span className="p-float-label">
                                <Dropdown id="tanzeemlevel-dropdown" className="w-full" options={eventLevels}
                                        optionLabel="label" value={null}
                                        onChange={e => {setVisibleEventTypesByTanzeemLevel(e.target.value)}}/>
                                <label htmlFor="tanzeemlevel-dropdown">Wähle die Tanzeem Ebene aus</label>
                              </span>
                    }
                </div>
            </div>
        </div>
    );
}