import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    toast: null
}
export const eventsSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
});

export const {setAppState} = eventsSlice.actions;

export default eventsSlice.reducer;