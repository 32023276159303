import {CalendarControllerApi, Configuration, EventControllerApi, EventTypeControllerApi} from "nap-events-client";
import environments from "./environments";
import {keycloak} from "../app_layout/Keycloak";
import {isMKADAccount} from "./userHelper";

export default class EventsClient {
    store;

    constructor(store) {
        this.store = store;
    }

    accessToken = () => {
        return isMKADAccount(keycloak) ? keycloak?.token : "";
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        if( this.accessToken())
            openapiConfig.baseOptions = {
                headers: { Authorization: 'Bearer ' + this.accessToken() },
            };
        
        openapiConfig.basePath = environments.eventsApiUrl;
        return openapiConfig;
    };

    eventTypesApi = () => {
        return new EventTypeControllerApi(this.configuration());
    };

    eventsApi = () => {
        return new EventControllerApi(this.configuration());
    };

    calendarApi = () => {
        return new CalendarControllerApi(this.configuration());
    };
}