/* tslint:disable */
/* eslint-disable */
/**
 * Events Service API
 * Maintain Events
 *
 * The version of the OpenAPI document: 1.0
 * Contact: fatha.idrees@khuddam.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from '../base';
/**
 * EventTypeControllerApi - axios parameter creator
 * @export
 */
export const EventTypeControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventType: (eventTypeDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventTypeDto' is not null or undefined
            assertParamExists('addEventType', 'eventTypeDto', eventTypeDto);
            const localVarPath = `/api/event/v1/eventTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(eventTypeDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} eventTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType: (eventTypeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventTypeId' is not null or undefined
            assertParamExists('deleteEventType', 'eventTypeId', eventTypeId);
            const localVarPath = `/api/event/v1/eventTypes/{eventTypeId}`
                .replace(`{${"eventTypeId"}}`, encodeURIComponent(String(eventTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventTypes: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteEventTypes', 'requestBody', requestBody);
            const localVarPath = `/api/event/v1/eventTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypes: (page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/event/v1/eventTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEventTypes: (search, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('searchEventTypes', 'search', search);
            const localVarPath = `/api/event/v1/eventTypes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(search, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} eventTypeId
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType: (eventTypeId, eventTypeDto, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventTypeId' is not null or undefined
            assertParamExists('updateEventType', 'eventTypeId', eventTypeId);
            // verify required parameter 'eventTypeDto' is not null or undefined
            assertParamExists('updateEventType', 'eventTypeDto', eventTypeDto);
            const localVarPath = `/api/event/v1/eventTypes/{eventTypeId}`
                .replace(`{${"eventTypeId"}}`, encodeURIComponent(String(eventTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication Bearer Authentication required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(eventTypeDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * EventTypeControllerApi - functional programming interface
 * @export
 */
export const EventTypeControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = EventTypeControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventType(eventTypeDto, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addEventType(eventTypeDto, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.addEventType']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} eventTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType(eventTypeId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteEventType(eventTypeId, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.deleteEventType']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventTypes(requestBody, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteEventTypes(requestBody, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.deleteEventTypes']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypes(page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllEventTypes(page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.getAllEventTypes']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEventTypes(search, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchEventTypes(search, page, size, sort, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.searchEventTypes']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} eventTypeId
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType(eventTypeId, eventTypeDto, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateEventType(eventTypeId, eventTypeDto, options);
                const index = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const operationBasePath = (_c = (_b = operationServerMap['EventTypeControllerApi.updateEventType']) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
            });
        },
    };
};
/**
 * EventTypeControllerApi - factory interface
 * @export
 */
export const EventTypeControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = EventTypeControllerApiFp(configuration);
    return {
        /**
         *
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventType(eventTypeDto, options) {
            return localVarFp.addEventType(eventTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} eventTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventType(eventTypeId, options) {
            return localVarFp.deleteEventType(eventTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventTypes(requestBody, options) {
            return localVarFp.deleteEventTypes(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypes(page, size, sort, options) {
            return localVarFp.getAllEventTypes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Search} search
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEventTypes(search, page, size, sort, options) {
            return localVarFp.searchEventTypes(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} eventTypeId
         * @param {EventTypeDto} eventTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType(eventTypeId, eventTypeDto, options) {
            return localVarFp.updateEventType(eventTypeId, eventTypeDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * EventTypeControllerApi - object-oriented interface
 * @export
 * @class EventTypeControllerApi
 * @extends {BaseAPI}
 */
export class EventTypeControllerApi extends BaseAPI {
    /**
     *
     * @param {EventTypeDto} eventTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    addEventType(eventTypeDto, options) {
        return EventTypeControllerApiFp(this.configuration).addEventType(eventTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} eventTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    deleteEventType(eventTypeId, options) {
        return EventTypeControllerApiFp(this.configuration).deleteEventType(eventTypeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    deleteEventTypes(requestBody, options) {
        return EventTypeControllerApiFp(this.configuration).deleteEventTypes(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    getAllEventTypes(page, size, sort, options) {
        return EventTypeControllerApiFp(this.configuration).getAllEventTypes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Search} search
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    searchEventTypes(search, page, size, sort, options) {
        return EventTypeControllerApiFp(this.configuration).searchEventTypes(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} eventTypeId
     * @param {EventTypeDto} eventTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeControllerApi
     */
    updateEventType(eventTypeId, eventTypeDto, options) {
        return EventTypeControllerApiFp(this.configuration).updateEventType(eventTypeId, eventTypeDto, options).then((request) => request(this.axios, this.basePath));
    }
}
