/* eslint-disable */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import tanzeemService from "./tanzeem.service";
import {capitalizeString, prettifyLabel} from "../../utils/utils";

const initialState = {
    tanzeems: undefined,
    tanzeemLevels: undefined,
    actionList : undefined,
    actionListLoading: false,
    isError: false,
    isSuccess: false,
    tanzeemsLoading: false,
    tanzeemLevelsLoading: false,
    statusMessage: ""
};


export const loadActionList: any = createAsyncThunk(
    "tanzeem/loadActionList",
    async ({callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await tanzeemService.loadActionList();
            if(typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response
        } catch (error:any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if(typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadTanzeems: any = createAsyncThunk(
    "tanzeem/loadTanzeems",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await tanzeemService.loadTanzeems(finalData);
            if(typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response.filter(tanzeem => tanzeem.key === "atfal" || tanzeem.key === "khuddam")
                            .map(tanzeem => { return {...tanzeem, label: prettifyLabel(tanzeem.key)}});
        } catch (error:any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if(typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadTanzeemLevels: any = createAsyncThunk(
    "tanzeem/loadTanzeemLevels",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await tanzeemService.loadTanzeemLevels(finalData);
            if(typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response.map(tanzeemLevel => { return {...tanzeemLevel, label: prettifyLabel(tanzeemLevel.key)}});
        } catch (error:any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if(typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const tanzeemSlice = createSlice({
    name: "tanzeem",
    initialState,
    reducers: {
        clearData: (state) => {
            state.tanzeemsLoading = false;
            state.tanzeemLevelsLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.statusMessage = "";
            tanzeems: [];
            tanzeemLevels: [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadActionList.pending, (state) => {
                state.actionListLoading = true;
            })
            .addCase(loadActionList.fulfilled, (state, action) => {
                state.actionListLoading = false;
                state.isSuccess = true;
                state.actionList = action.payload;
            })
            .addCase(loadActionList.rejected, (state, action) => {
                state.actionListLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadTanzeems.pending, (state) => {
                state.tanzeemsLoading = true;
            })
            .addCase(loadTanzeems.fulfilled, (state, action) => {
                state.tanzeemsLoading = false;
                state.isSuccess = true;
                state.tanzeems = action.payload;
            })
            .addCase(loadTanzeems.rejected, (state, action) => {
                state.tanzeemsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadTanzeemLevels.pending, (state) => {
                state.tanzeemLevelsLoading = true;
            })
            .addCase(loadTanzeemLevels.fulfilled, (state, action) => {
                state.tanzeemLevelsLoading = false;
                state.isSuccess = true;
                state.tanzeemLevels = action.payload;
            })
            .addCase(loadTanzeemLevels.rejected, (state, action) => {
                state.tanzeemLevelsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
    },
});

export const {clearData} = tanzeemSlice.actions;

export default tanzeemSlice.reducer;
