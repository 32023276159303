import React, {useState} from "react";
import {Panel} from "primereact/panel";
import moment from "moment";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {updateEventStatus} from "../../store/events/events.slice";
import {useDispatch} from "react-redux";
import {defaultErrorMessageToast, getToast} from "../../utils/toastHelper";
import {InputTextarea} from "primereact/inputtextarea";
import {Divider} from "primereact/divider";


export default function CancelRejectEvent(props) {
    const dispatch = useDispatch();
    const {mode, status} = props;
    const [event, setEvent] = useState(props.event);
    const [newComment, setNewComment] = useState();
    const toast = getToast();
    const toastErrorMessage = (response) => {defaultErrorMessageToast(toast,response)};

    const eventUpdated = (response) => {
        props.onApprove ? props.onApprove() : void(0);
        props.onDone ? props.onDone() : void(0);
    };

    const updateStatusOfEvent  = () => {
        console.log(newComment)
        console.log(typeof(newComment))
        if(!newComment || newComment.length < 3)
        {
            setNewComment(null)
        }    
        else{
            dispatch(updateEventStatus({finalData: {eventId: event.id, status: status, additionalData: {comment: newComment}}, callbackSuccess: eventUpdated, callbackError: toastErrorMessage}))    
        }
    }
    
    return <React.Fragment>
            <InputTextarea className={newComment === null ? "w-100 p-invalid" : "w-100"}
                          value={newComment}
                          placeholder="Bitte geben Sie hier ein Grund ein!"
                          rows={8}
                          onChange={(e) => setNewComment(e.target.value)}/>
            <Button severity="success" className="w-5" icon="pi pi-check" label="Bestätigen" onClick={updateStatusOfEvent}/>
            </React.Fragment>
}