import {Field} from "react-final-form";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import React from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {RadioButton} from "primereact/radiobutton";
import {Checkbox} from "primereact/checkbox";
import {MultiSelect} from "primereact/multiselect";
import {MultiStateCheckbox} from "primereact/multistatecheckbox";
import {InputNumber} from "primereact/inputnumber";


export const getInputText = (key, label, isFormFieldValid, getFormErrorMessage, disabled) => {
    return <Field name={key} render={({input, meta}) => (
        <div className="field">
                                    <span className="p-float-label">
                                        <InputText disabled={disabled} id={key} {...input} autoFocus className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                        <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                                    </span>
            {getFormErrorMessage(meta)}
        </div>
    )}/>
}

export const getInputNumber = (key, label, isFormFieldValid, getFormErrorMessage, disabled, suffix) => {
    return <Field name={key} render={({input, meta}) => (
        <div className="field">
                                    <span className="p-float-label">
                                        <span className="p-inputgroup customNumbers">
                                        <InputText keyfilter="int" disabled={disabled} id={key} {...input} autoFocus className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                        <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                                            {suffix ?     <span className="p-inputgroup-addon">{suffix}</span> : null}    
                                        </span>
                                        
                                        
                                    </span>
            {getFormErrorMessage(meta)}
        </div>
    )}/>
}

export const getRadioButton = (key, label, isFormFieldValid, getFormErrorMessage, disabled) => {
    return <Field name={key} type="radio" render={({input, meta}) => (
                <div className="field-radiobutton">
                    <RadioButton disabled={disabled} id="{key}" {...input} className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                    {getFormErrorMessage(meta)}
           </div>
    )}/>
}

export const getRadioButtons = (key, options, additionalClassName, isFormFieldValid, getFormErrorMessage, disabled) => {
    let errorMessage = "";

    return <React.Fragment>
                {options.map( option => {
                    return <Field name={key} type="radio" value={option.value} render={({input, meta}) => (
                                <div className={"field-radiobutton "+additionalClassName}>
                                    <RadioButton disabled={disabled} id={option.label} {...input} className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                    <label htmlFor={option.label} className={classNames({'p-error': isFormFieldValid(meta)})}>{option.label}</label>
                                </div>
                    )}/> 
                })}
                <Field name={key} type="radio" render={({input, meta}) => (
                     getFormErrorMessage(meta)
                )}/>
            </React.Fragment>
}

export const getCheckBox = (key, label, isFormFieldValid, getFormErrorMessage, disabled) => {
    return <Field name={key} type="checkbox" render={({input, meta}) => (
                <div className="field-checkbox">
                    <Checkbox id={key} disabled={disabled} {...input} className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                    {getFormErrorMessage(meta)}
                </div>
    )} />
}

export const getMultiStateCheckBox = (key, label, options, noValue, isFormFieldValid, getFormErrorMessage, disabled) => {
    return <Field name={key} render={({input, meta}) => (
        <div>
            <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
            <div className="field-checkbox">
                <MultiStateCheckbox id={key} disabled={disabled} {...input} options={options} optionLabel="label" optionValue="value" className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                <label htmlFor={key} className={classNames({'p-error': isFormFieldValid(meta)})}>{input.value || noValue}</label>
                {getFormErrorMessage(meta)}
            </div>
        </div>
    )} />
}




export const getSelectionDropdown = (key, label, options, optionLabel, isFormFieldValid, getFormErrorMessage) => {
    return <Field name={key} render={({input, meta}) => (
            <div className="field">
                <span className="p-float-label">
                    <Dropdown id={key} {...input} options={options} optionLabel={optionLabel} className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <label htmlFor={key}  className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                </span>
                {getFormErrorMessage(meta)}
            </div>
    )}/>
}

export const getMultiSelectionDropdown = (key, label, options, optionLabel, isFormFieldValid, getFormErrorMessage) => {
    return <Field name={key} multiple render={({input, meta}) => (
        <div className="field">
                <span className="p-float-label">
                    <MultiSelect id={key} {...input} options={options} optionLabel={optionLabel} className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <label htmlFor={key}  className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                </span>
            {getFormErrorMessage(meta)}
        </div>
    )}/>
}

export const getInputTextArea = (key, label, isFormFieldValid, getFormErrorMessage, disabled, rows) => {
    return <Field name={key} render={({input, meta}) => (
        <div className="field">
                                    <span className="p-float-label p-input-icon-right">
                                        <i className="pi"/>
                                        <InputTextarea disabled={disabled} id={key} {...input}
                                                       className={classNames({'p-invalid': isFormFieldValid(meta)})} rows={rows ? rows : 3}/>
                                        <label htmlFor={key}
                                               className={classNames({'p-error': isFormFieldValid(meta)})}>{label}</label>
                                    </span>
            {getFormErrorMessage(meta)}
        </div>)}/>
}





