import Keycloak, {KeycloakInitOptions, KeycloakLogoutOptions} from "keycloak-js";
import environments from "../utils/environments";

export const keycloak = new Keycloak({
    "url": environments.authUrl,
    "realm": environments.authRealm,
    "clientId": environments.authClient
});

export const keycloakProviderInitConfig:KeycloakInitOptions = {
    onLoad: 'check-sso',
    //onLoad: 'check-sso',
    checkLoginIframe:false
}

export const keycloakProviderLogoutConfig:KeycloakLogoutOptions = {
    redirectUri: window.location.origin + '/'
}