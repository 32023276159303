import {eventsClient} from "../../index";

const createEventType = async (eventType) => {
  const response = await eventsClient.eventTypesApi().addEventType(eventType);
  return response.data;
};

// Get All list of projects
const loadEventTypes = async (data) => {
  const response = await eventsClient.eventTypesApi().getAllEventTypes(data.page,data.size);
  return response.data;
};

const updateEventType = async (eventType) => {
  const response = await eventsClient.eventTypesApi().updateEventType(eventType.id,eventType);
  return response.data;
};

// Delete projects
const deleteEventTypes = async (data) => {
  const response = await eventsClient.eventTypesApi().deleteEventTypes(data);
  return response.data;
};

// Get Single projects
const getEventTypeById = async (data) => {
  //const response = await eventsClient.eventTypesApi().get(data.eventTypeId)
  return null;
};

const eventTypes = {
  loadEventTypes, 
  createEventType,
  updateEventType,
  getEventTypeById,
  deleteEventTypes,
};

export default eventTypes;