/* eslint-disable */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import events from "./events.service";

const initialState = {
    event: null,
    events: null,
    eventsLoading: false,
    createdEvent: null,
    createEventLoading: false,
    updatedEvent: null,
    updatedEventStatusLoading: false,
    updateEventLoading: false,
    deleteEvents: null,
    deleteEventLoading: false,
    isError: false,
    isSuccess: false,
    statusMessage: ""
};

export const loadEvents: any = createAsyncThunk(
    "events/loadEvents",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.loadEvents(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createEvent: any = createAsyncThunk(
    "events/createEvent",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.createEvent(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateEvent: any = createAsyncThunk(
    "events/updateEvent",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.updateEvent(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateEventStatus: any = createAsyncThunk(
    "events/updateEventStatus",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.updateEventStatus(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateEventsStatus: any = createAsyncThunk(
    "events/updateEventsStatus",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.updateEventsStatus(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const addComment: any = createAsyncThunk(
    "events/addComment",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.addComment(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteEvents: any = createAsyncThunk(
    "events/deleteEvent",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await events.deleteEvents(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const eventsSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        clearData: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.statusMessage = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEvent.pending, (state) => {
                state.createEventLoading = true;
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.createEventLoading = false;
                state.isSuccess = true;
                state.createdEvent = action.payload;
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.createEventLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadEvents.pending, (state) => {
                state.eventsLoading = true;
            })
            .addCase(loadEvents.fulfilled, (state, action) => {
                state.eventsLoading = false;
                state.isSuccess = true;
                state.events = action.payload;
            })
            .addCase(loadEvents.rejected, (state, action) => {
                state.eventsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(updateEvent.pending, (state) => {
                state.updateEventLoading = true;
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.updateEventLoading = false;
                state.isSuccess = true;
                state.updatedEvent = action.payload;
            })
            .addCase(updateEvent.rejected, (state, action: any) => {
                state.updateEventLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(updateEventStatus.pending, (state) => {
                state.updatedEventStatusLoading = true;
            })
            .addCase(updateEventStatus.fulfilled, (state, action) => {
                state.updatedEventStatusLoading = false;
                state.isSuccess = true;
                state.updatedEvent = action.payload;
            })
            .addCase(updateEventStatus.rejected, (state, action: any) => {
                state.updatedEventStatusLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(updateEventsStatus.pending, (state) => {
                state.updatedEventStatusLoading = true;
            })
            .addCase(updateEventsStatus.fulfilled, (state, action) => {
                state.updatedEventStatusLoading = false;
                state.isSuccess = true;
                //state.updatedEvent = action.payload;
            })
            .addCase(updateEventsStatus.rejected, (state, action: any) => {
                state.updatedEventStatusLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(deleteEvents.pending, (state) => {
                state.deleteEventLoading = true;
            })
            .addCase(deleteEvents.fulfilled, (state, action: any) => {
                // const deleteMultipleEvents = [...state.eventDetails];
                // deleteMultipleEvents = state.eventDetails.filter(
                //   (item) => item.eventId !== action.payload
                // );
                state.deleteEventLoading = false;
                state.isSuccess = true;
                state.deleteEvents = action.payload;
            })
            .addCase(deleteEvents.rejected, (state, action: any) => {
                state.deleteEventLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
    },
});

export const {clearData} = eventsSlice.actions;

export default eventsSlice.reducer;
