import {useSelector} from "react-redux";
import {booleanOptions, initialTypes, optionalDataOptions, participantsOptions} from "./EventTypes";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import {Dialog} from "primereact/dialog";
import {Field, Form} from "react-final-form";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Calendar} from "primereact/calendar";
import {Accordion, AccordionTab} from "primereact/accordion";
import {
    getCheckBox,
    getInputText,
    getInputTextArea, getMultiSelectionDropdown, getMultiStateCheckBox,
    getRadioButton,
    getRadioButtons,
    getSelectionDropdown
} from "../../../utils/inputHelper";
import {RadioButton} from "primereact/radiobutton";
import {Checkbox} from "primereact/checkbox";

export default function CreateUpdateEventType(props){
    const {tanzeems ,tanzeemLevels} = useSelector(state => state.tanzeem)
    const {eventType} = props
    
    const initialValues={
        flyerTemplateAvailable: false, 
        flyerRequestable: false,
        participants: "ALL",
        budgetRequired:	"not-available",
        agendaRequired: "not-available",
        budgetSelfInvolvementRequired: "not-available",
        customTitleSettable: false,
        visitRequestable: false,
        approvalRequired: false,
        
    }
    
    if(eventType){
        initialValues.label = eventType.label
        initialValues.budgetRequired = eventType.budgetRequired
        initialValues.agendaRequired = eventType.agendaRequired
        initialValues.approvalRequired = eventType.approvalRequired
        initialValues.visitRequestable = eventType.visitRequestable
        initialValues.customTitleSettable = eventType.customTitleSettable
        initialValues.budgetSelfInvolvementRequired = eventType.budgetSelfInvolvementRequired
        initialValues.flyerTemplateAvailable = eventType.flyerTemplateAvailable
        initialValues.flyerRequestable = eventType.flyerRequestable
        initialValues.participants = eventType.eventTypeFor
        initialValues.tanzeem = tanzeems.find(item => item.key === eventType.tanzeem)
        initialValues.tanzeemLevel = tanzeemLevels.find(item => item.key === eventType.tanzeemLevel)
    }
    console.log(initialValues)
    
    const validate = (data) => {
        let errors = {};

        const requiredFields = ["label", "tanzeem", "tanzeemLevel", "participants", "budgetRequired", 
            "agendaRequired", "budgetSelfInvolvementRequired", "approvalRequired", "visitRequestable", "customTitleSettable", "flyerTemplateAvailable", "flyerRequestable"]

        requiredFields.forEach(requiredField => {
            if (typeof data[requiredField] === 'undefined' || typeof data[requiredField] === null) {
                errors[requiredField] = 'Field is required.';
            }
        })
        return errors;
    };

    const onSubmit = (data, form) => {
        form.restart();
        props.onSubmit(data)
        
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };
    
    return (
        <div className="form-demo">
            <div className="flex justify-content-center">
                <div className="card">
                    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            {getInputText("label", "Name", isFormFieldValid, getFormErrorMessage)}
                            {getSelectionDropdown("tanzeem", "Tanzeem", tanzeems, "label", isFormFieldValid, getFormErrorMessage)}
                            {getSelectionDropdown("tanzeemLevel", "Tanzeem Level", tanzeemLevels, "label", isFormFieldValid, getFormErrorMessage)}
                            <div className="field">
                                <label className="mb-3 font-bold">Teilnehmer</label>
                                <div className="formgrid grid">
                                    {getRadioButtons("participants", participantsOptions, "col-6", isFormFieldValid, getFormErrorMessage)}
                                </div>
                            </div>
                            <div className="field">
                                <label className="mb-3 font-bold">Budget Angaben</label>
                                <div className="formgrid grid">
                                    {getRadioButtons("budgetRequired", optionalDataOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                </div>
                            </div>
                            <div className="field">
                                <label className="mb-3 font-bold">Selbstbeteiligung Angaben</label>
                                <div className="formgrid grid">
                                    {getRadioButtons("budgetSelfInvolvementRequired", optionalDataOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                </div>
                            </div>
                            <div className="field">
                                <label className="mb-3 font-bold">Agenda Angabe</label>
                                <div className="formgrid grid">
                                    {getRadioButtons("agendaRequired", optionalDataOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label className="mb-3 font-bold">Freigabe benötigt?</label>
                                    <div className="formgrid grid">
                                        {getRadioButtons("approvalRequired", booleanOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                    </div>
                                </div>
    
                                <div className="field col-6">
                                    <label className="mb-3 font-bold">Besuch Anfrage erlaubt?</label>
                                    <div className="formgrid grid">
                                        {getRadioButtons("visitRequestable", booleanOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                    </div>
                                </div>
                                
                                <div className="field col-6">
                                    <label className="mb-3 font-bold">Flyer Template</label>
                                    <div className="formgrid grid">
                                        {getRadioButtons("flyerTemplateAvailable", booleanOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                    </div>
                                </div>
                                <div className="field col-6">
                                    <label className="mb-3 font-bold">Flyer Anfrage</label>
                                    <div className="formgrid grid">
                                        {getRadioButtons("flyerRequestable", booleanOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                    </div>
                                </div>
                                <div className="field col-6">
                                    <label className="mb-3 font-bold">Eigenen Titel erlauben</label>
                                    <div className="formgrid grid">
                                        {getRadioButtons("customTitleSettable", booleanOptions, "col-4", isFormFieldValid, getFormErrorMessage)}
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" label="Speichern" className="mt-2" />
                        </form>
                    )} />
                </div>
            </div>
        </div>
    );
}