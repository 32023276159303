import { useDispatch } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import PropTypes from "prop-types";
import AppLayout from "../../app_layout/AppLayout";
import Unauthorized from "../../pages/general/Unauthorized";
import React from "react";
import FullOverlaySpinner from "../../components/LoadingSpinner/FullOverlaySpinner";

function ProtectedRoute({ element }) {
  const { keycloak, initialized } = useKeycloak();

  if(!initialized)
  {
    return <AppLayout children={<FullOverlaySpinner show={!initialized}/>}/> ;
  }
  
  return <AppLayout children={element}/>;
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
