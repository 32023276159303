import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/app.slice";
import eventTypesReducer from "./eventTypes/eventTypes.slice";
import eventsReducer from "./events/events.slice";
import calendarsReducer from "./calendar/calendar.slice";
import tanzeemReducer from "./tanzeem/tanzeem.slice";

const store = configureStore({
  reducer: {
    app: appReducer,
    tanzeem: tanzeemReducer,
    eventTypes: eventTypesReducer,
    events: eventsReducer,
    calendars: calendarsReducer
  },
});

export default store;
