import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import {Provider} from "react-redux";
import store from "./store/store";
import {keycloak, keycloakProviderInitConfig} from "./app_layout/Keycloak";
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import {EnhancedStore} from "@reduxjs/toolkit/src/configureStore";
import EventsClient from "./utils/eventsClient";
import TanzeemClient from "./utils/tanzeemClient";


let injected_store
export const injectStore = (_store: EnhancedStore) => {
    injected_store = _store
}
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

injectStore(store)

export const eventsClient = new EventsClient(store);
export const tanzeemClient = new TanzeemClient(store);

root.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig}>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
