import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Routes/ProtectedRoute.jsx";
import WEB_PAGES from "../pages";
import PATH from "../utils/path";
import UnProtectedRoute from "./Routes/UnProtectedRoute";

function RouterConfig() {
  return (
      <Routes>
          <Route
              path={PATH.ROOT}
              element={<UnProtectedRoute element={<WEB_PAGES.DASHBOARD/>}/>}
          />
          <Route
              path={PATH.DASHBOARD}
              element={<UnProtectedRoute element={<WEB_PAGES.DASHBOARD/>}/>}
          />
          <Route
              path={PATH.EVENTS}
              element={
                  <ProtectedRoute element={<WEB_PAGES.EVENTS/>}/>
              }
          />
          <Route
              path={PATH.EVENTS_TYPES}
              element={
                  <ProtectedRoute element={<WEB_PAGES.EVENTS_TYPES/>}/>
              }
          />
      </Routes>
  );
}

export default RouterConfig;
