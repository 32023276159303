import {tanzeemClient} from "../../index";

const loadTanzeems = async (data) => {
  const response = await tanzeemClient.tanzeemApi().getTanzeems();
  return response.data;
};

const loadTanzeemLevels = async (data) => {
  const response = await tanzeemClient.tanzeemLevelApi().getTanzeemLevels();
  return response.data;
};

const loadActionList = async () => {
  const response = await tanzeemClient.meApi().getActionsList();
  return response.data;
};

const tanzeemService = {
  loadTanzeems,
  loadTanzeemLevels,
  loadActionList
};

export default tanzeemService;