/* eslint-disable */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import eventTypes from "./eventTypes.service";

const initialState = {
    eventType: null,
    eventTypes: null,
    allEventTypes: null,
    allEventTypesLoading: false,
    eventTypesLoading: false,
    createdEventType: null,
    createEventTypeLoading: false,
    updatedEventType: null,
    updateEventTypeLoading: false,
    deleteEventTypes: null,
    deleteEventTypeLoading: false,
    isError: false,
    isSuccess: false,
    statusMessage: ""
};

export const loadEventTypes: any = createAsyncThunk(
    "eventTypes/loadEventTypes",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await eventTypes.loadEventTypes(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadAllEventTypes: any = createAsyncThunk(
    "eventTypes/loadAllEventTypes",
    async ({callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await eventTypes.loadEventTypes({page:0, size:-1});
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createEventType: any = createAsyncThunk(
    "eventTypes/createEventType",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await eventTypes.createEventType(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateEventType: any = createAsyncThunk(
    "eventTypes/updateEventType",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await eventTypes.updateEventType(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteEventTypes: any = createAsyncThunk(
    "eventTypes/deleteEventType",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await eventTypes.deleteEventTypes(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response && error.response.data && error.response.data) || error.message || error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const eventTypesSlice = createSlice({
    name: "eventTypes",
    initialState,
    reducers: {
        clearData: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.statusMessage = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEventType.pending, (state) => {
                state.createEventTypeLoading = true;
            })
            .addCase(createEventType.fulfilled, (state, action) => {
                state.createEventTypeLoading = false;
                state.isSuccess = true;
                state.createdEventType = action.payload;
            })
            .addCase(createEventType.rejected, (state, action) => {
                state.createEventTypeLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadEventTypes.pending, (state) => {
                state.eventTypesLoading = true;
            })
            .addCase(loadEventTypes.fulfilled, (state, action) => {
                state.eventTypesLoading = false;
                state.isSuccess = true;
                state.eventTypes = action.payload;
            })
            .addCase(loadEventTypes.rejected, (state, action) => {
                state.allEventTypesLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadAllEventTypes.pending, (state) => {
                state.eventTypesLoading = true;
            })
            .addCase(loadAllEventTypes.fulfilled, (state, action) => {
                state.allEventTypesLoading = false;
                state.isSuccess = true;
                state.allEventTypes = action.payload;
            })
            .addCase(loadAllEventTypes.rejected, (state, action) => {
                state.allEventTypesLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(updateEventType.pending, (state) => {
                state.updateEventTypeLoading = true;
            })
            .addCase(updateEventType.fulfilled, (state, action) => {
                state.updateEventTypeLoading = false;
                state.isSuccess = true;
                state.updatedEventType = action.payload;
            })
            .addCase(updateEventType.rejected, (state, action: any) => {
                state.updateEventTypeLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(deleteEventTypes.pending, (state) => {
                state.deleteEventTypeLoading = true;
            })
            .addCase(deleteEventTypes.fulfilled, (state, action: any) => {
                // const deleteMultipleEventTypes = [...state.eventTypeDetails];
                // deleteMultipleEventTypes = state.eventTypeDetails.filter(
                //   (item) => item.eventTypeId !== action.payload
                // );
                state.deleteEventTypeLoading = false;
                state.isSuccess = true;
                state.deleteEventTypes = action.payload;
            })
            .addCase(deleteEventTypes.rejected, (state, action: any) => {
                state.deleteEventTypeLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
    },
});

export const {clearData} = eventTypesSlice.actions;

export default eventTypesSlice.reducer;
