import React from "react";
import {Card} from "primereact/card";

export default function Unauthorized(props:any){
    
    return (
        <div className="content">
            <div className="card">
                <Card title="Access Denied">
                    <p className="m-0">
                        Sorry, you do not have enough permission, to access the Content.
                    </p>
                </Card>
            </div>
        </div>
    )
}