

export const capitalizeString = (input) => {
    return capitalizeStringWithDelimiter(input, " ");    
}

export const capitalizeStringWithDelimiter = (input, delimiter) => {
    if(!input)
        return input
    const arr = input.split(delimiter);
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    const result = arr.join(delimiter);
    return result;
}

export const prettifyLabel = (input) => {
    let result = capitalizeString(input)
    result = capitalizeStringWithDelimiter(result, "-");
    return setUmlaute(result)
}

const setUmlaute = (input) => {
    if(input)
        return input.replace("AE", "Ä")
                .replace("Ae", "Ä")
                .replace("ae", "ä")
                .replace("OE", "Ö")
                .replace("Oe", "Ö")
                .replace("oe", "ö")
                .replace("UE", "Ü")
                .replace("Ue", "Ü")
                .replace("ue", "ü")
    
    return input
}

export const capitalizeFirstChar = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
}