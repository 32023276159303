/* eslint-disable */

import PropTypes, {ReactNodeLike} from "prop-types";
import {useLocation, Link, useParams, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Menubar} from "primereact/menubar";
import {PanelMenu} from "primereact/panelmenu";
import React, {useEffect, useRef} from "react";
import logo from "../assets/mkad-logo.png";
import {MenuItem, MenuItemCommandEvent} from "primereact/menuitem";
import {keycloak, keycloakProviderLogoutConfig} from "./Keycloak";
import {Avatar} from "primereact/avatar";
import {TieredMenu} from "primereact/tieredmenu";
import {PAGES_INFO} from "../utils/path";
import {loadActionList} from "../store/tanzeem/tanzeem.slice";
import FullOverlaySpinner from "../components/LoadingSpinner/FullOverlaySpinner";
import {Toast} from "primereact/toast";
import {defaultDebugMessageToast, defaultErrorMessageToast, setToast} from "../utils/toastHelper";
import {getUsersTanzeemUnitsCapitalized, hasOneOfRole, isMKADAccount} from "../utils/userHelper";
import {Message} from "primereact/message";

function AppLayout({children}:any) {
  const {actionList, actionListLoading} = useSelector((state:any) => state.tanzeem)
  const location = useLocation();
  const navigate = useNavigate();
  const menu = useRef<TieredMenu>(null);
  const param = useParams();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const toastCenter = useRef(null);
  const toastErrorMessage = (response:any) => {defaultErrorMessageToast(toast,response)};
  const toastDebugMessage = (response:any) => {defaultDebugMessageToast(toast,response)};
  
  useEffect(() => {
    setToast(toast)
  }, [toast]);
  
  useEffect(()=> {
    
    if(!actionList && isMKADAccount(keycloak))
      dispatch(loadActionList({callbackSuccess: toastDebugMessage, callbackError: toastErrorMessage }))
    
  },[keycloak.authenticated])
  
  let sideMenuItems:any[] = [];

  // if (user && role === "admin")
  //   sideMenuItems = [...SIDE_MENU_LIST.ADMIN, ...sideMenuItems];
  // if (user && role === "corporate")
  //   sideMenuItems = [...SIDE_MENU_LIST.CORPORATE, ...sideMenuItems];
  // if (user && role === "individual")
  //   sideMenuItems = [...SIDE_MENU_LIST.INDIVIDUAL, ...sideMenuItems];
  // if (user && role === "projectManager")
  //   sideMenuItems = [...SIDE_MENU_LIST.PROJECT_MANAGER, ...sideMenuItems];
  // const profileDropdown = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <Link className="text-dark" to={PATH.PROFILE_SETTING}>
  //             Profile Setting
  //           </Link>
  //         ),
  //       },
  //       {
  //         key: "2",
  //         label: (
  //           <Link
  //             className="text-dark"
  //             to={location.pathname}
  //             onClick={logoutFun}
  //           >
  //             Logout
  //           </Link>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  // const footerMenuItems = [
  //   // {
  //   //   // key: PATH.ADMIN_DASHBOARD,
  //   //   icon: <img src={IMAGES.HELP_ICON} alt="" />,
  //   //   label: (
  //   //     <Link to={location.pathname} onClick={logoutFun}>
  //   //       Help
  //   //     </Link>
  //   //   ),
  //   // },
  //   {
  //     // key: PATH.ADMIN_PROJECTS,
  //     icon: <img src={IMAGES.LOGOUT} alt="" />,
  //     label: (
  //       <Link to={location.pathname} onClick={logoutFun}>
  //         Logout
  //       </Link>
  //     ),
  //   },
  // ];
  const getActualParam = (item:any) => {
    const actualParam = param[item.param];
    const actualPath = item.path.replace(`:${item.param}`, actualParam);
    return actualPath;
  };
  
  const menuItems: MenuItem[] = PAGES_INFO.filter(pageInfo => hasOneOfRole(keycloak, pageInfo.roles)).map(pageInfo => {
    let subitems: MenuItem[] | undefined = undefined;
    if(pageInfo.pages?.length > 0)
    {
      subitems = pageInfo.pages?.filter((subItem:any) => hasOneOfRole(keycloak, subItem.roles)).map( (subItem:any) => {
        return {
          id:subItem.title,
          label: subItem.title,
          icon: "pi pi-fw "+subItem.icon,
          className: location.pathname === subItem.path ? "selectedNavItem": "",
          command: (event) => {navigate(subItem.path);}
        }});
    }

    return {
      id:pageInfo.title,
      label: pageInfo.title,
      icon: "pi pi-fw "+pageInfo.icon,
      expanded: subitems !== undefined && location.pathname.startsWith(pageInfo.pathName),
      command: (event) => {subitems === undefined ? navigate(pageInfo.pathName) :void(0)},
      items: subitems,
      className: location.pathname === pageInfo.pathName  ? "p-menuitem-active" : "",
    } 
    
  })
  const items: MenuItem[] = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      items: [
        {
          label: "Item 1",
          icon: "pi pi-fw pi-file",
        },
        {
          label: "Item 2",
          icon: "pi pi-fw pi-cog",
        },
      ],
    },
    {
      label: "Reports",
      icon: "pi pi-fw pi-chart-bar",
      items: [
        {
          label: "Item 3",
          icon: "pi pi-fw pi-list",
        },
        {
          label: "Item 4",
          icon: "pi pi-fw pi-table",
        },
      ],
    },
  ];
  const profileItems: MenuItem[] = [];
  
  
  profileItems.push({
    label: "Logout",
    icon: "pi pi-logout",
    command: (event: MenuItemCommandEvent) => keycloak.logout(keycloakProviderLogoutConfig)
  },)
  
  const otherProfileItems: MenuItem[] = [
    {
      label: "Profile",
      icon: "pi pi-fw pi-chart-bar",
      command: (event: MenuItemCommandEvent) => {
        console.log(keycloak.tokenParsed?.tanzeemManager)
        console.log(keycloak.tokenParsed?.roles)
        console.log(keycloak.tokenParsed?.roleNames)
        console.log(keycloak.tokenParsed)
        console.log(keycloak.token)
      }
    }
  ];
  
  //if(keycloak.authenticated)
    //profileItems.push(...otherProfileItems);

  const endItems = [
      {
      template: (item:any, index:number) => (
          <div key={index} className="profile-icon">
            <img src={logo} height="42px"/>
          </div>
      ),
    },
  ];
  
  const givenName = keycloak.authenticated ? keycloak.tokenParsed?.given_name : ""
  const surname = keycloak.authenticated ? keycloak.tokenParsed?.family_name : ""
  const usersName = keycloak.authenticated ? givenName + " " + surname : ""
  const capitalizedUnits = Object.entries(getUsersTanzeemUnitsCapitalized(keycloak))
      .map(([unitLevel, unitName]) => <React.Fragment><br/><span style={{"fontSize": "0.8rem", color: "gray"}}>{unitLevel + ": "+ unitName}</span></React.Fragment>)
  const usersData = <React.Fragment>{!isMKADAccount(keycloak) ? <Message severity="warn" text="Please use MKAD Software Account, the MS365 Account are currently not supported"/>: ""}<span>{usersName}</span>{capitalizedUnits}</React.Fragment>

  const profileAvatar = !keycloak.authenticated ? <div><a href='javascript:void(0)' style={{color: "red", fontWeight: "bold"}} onClick={() => keycloak.login()}>LOGIN</a> </div>:
      <div style={{"display": "inline-flex"}}><div>{usersData}</div>
        <div className="profile-icon">
          <Avatar icon="pi pi-user" shape="circle" size="large" onClick={(e) => menu.current?.toggle(e)}/>
          <TieredMenu model={profileItems} popup ref={menu} breakpoint="767px" />
        </div>
      </div>
  
  
  return (
      <React.Fragment>
        <Toast ref={toast}/>
        <FullOverlaySpinner show={actionListLoading}/>
        <div className="header">
          <Menubar start={() => <div>{endItems.map((item:any, index:number) => item.template(item,index))}</div>} end={profileAvatar} />
        </div>
        <div className="dashboard-layout">
          {isMKADAccount(keycloak) ? <div className="sidebar">
            <Menubar model={menuItems} style={{ height: "100%" }} />
          </div> : ""}
          {children}
        </div>
      </React.Fragment>
    
  );
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppLayout;
