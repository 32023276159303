import {keycloak} from "../app_layout/Keycloak";
import {capitalizeString, prettifyLabel} from "./utils";


export const getUserParentUnits = (keycloak)=> {
    return keycloak.tokenParsed ? keycloak.tokenParsed.tanzeemManager?.parentTanzeemUnitKeys : null    
}

export const getUsersTanzeemUnitByLevel = (keycloak, unitLevel)=> {
    const parentUnits = getUserParentUnits(keycloak);
    if(parentUnits !== null && parentUnits[unitLevel])
        return parentUnits[unitLevel]

    return ""
}

export const isAdmin = (keycloak)=> {
    return keycloak.authenticated && keycloak.tokenParsed.roles?.filter(roleId => roleId === "LEGACY_USER_TYPE:admin").length > 0
}

export const isAitmad = (keycloak)=> {
    return keycloak.authenticated && keycloak.tokenParsed.roles?.filter(roleId => roleId === "LEGACY_USER_TYPE:aitmad").length > 0
}

export const isNational = (keycloak)=> {
    return isAitmad(keycloak) || isAdmin(keycloak) || keycloak.authenticated && keycloak.tokenParsed.roles?.filter(roleId => roleId === "LEGACY_USER_TYPE:national").length > 0
}

export const isRegional = (keycloak)=> {
    return keycloak.authenticated && keycloak.tokenParsed.roles?.filter(roleId => roleId === "LEGACY_USER_TYPE:region").length > 0
}

export const isManager = (keycloak)=> {
    return isAitmad(keycloak) || isAdmin(keycloak)
}

export const hasOneOfRole = (keycloak, roles)=> {
    if (keycloak.authenticated && roles?.length > 0)
    {
        const userRoles = keycloak.tokenParsed.roles;
        let rolePassed = false;
        roles.forEach(role => {
            if(userRoles && userRoles.filter(roleId => roleId === "LEGACY_USER_TYPE:"+role).length > 0)
                rolePassed = true;
        })
            return rolePassed;
    }
    else return roles?.length === 0;
}

export const isUsersAimsId = (keycloak, aimsId)=> {
    return keycloak.authenticated && keycloak.tokenParsed.tanzeemManager.aimsId === aimsId
}

export const isMKADAccount = (keycloak) => {
    return keycloak.authenticated && keycloak.tokenParsed?.accountType === "Khuddam-ul-Ahmadiyya"
}

export const getUsersTanzeemUnitByLevelCapitalized = (keycloak, unitLevel)=> {
        return prettifyLabel(getUsersTanzeemUnitByLevel(keycloak, unitLevel))
    return ""
}
export const getUsersTanzeemUnitsCapitalized = (keycloak)=> {
    const parentUnits = getUserParentUnits(keycloak);
    const capitalizedUnits = {};
    if(parentUnits)
        Object.keys(parentUnits).forEach(unitLevel => {
            if(unitLevel !== "national")
            {
                capitalizedUnits[capitalizeString(unitLevel)] = prettifyLabel(parentUnits[unitLevel])
            }
        })

    return capitalizedUnits;
}