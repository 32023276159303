/* eslint-disable */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import calendars from "./calendar.service";

const initialState = {
    calendarEvents: null,
    calendarEventsLoading: false,
    majalis: null,
    majalisLoading: false,
    regions: null,
    regionLoading: false,
    majalisByParent: null,
    majalisByParentLoading: false,
    isError: false,
    isSuccess: false,
    statusMessage: ""
};

export const loadCalendarEvents: any = createAsyncThunk(
    "calendars/loadCalendarEvents",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await calendars.loadCalendarEvents(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadMajalis: any = createAsyncThunk(
    "calendars/loadMajalis",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await calendars.loadMajalis(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadRegions: any = createAsyncThunk(
    "calendars/loadRegions",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await calendars.loadRegions(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadMajalisByPanrent: any = createAsyncThunk(
    "calendars/loadMajalisByPanrent",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await calendars.loadUnitsByParent(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error: any) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const calendarSlice = createSlice({
    name: "calendars",
    initialState,
    reducers: {
        clearData: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.statusMessage = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCalendarEvents.pending, (state) => {
                state.calendarEventsLoading = true;
            })
            .addCase(loadCalendarEvents.fulfilled, (state, action) => {
                state.calendarEventsLoading = false;
                state.isSuccess = true;
                state.calendarEvents = action.payload;
            })
            .addCase(loadCalendarEvents.rejected, (state, action) => {
                state.calendarEventsLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadMajalis.pending, (state) => {
                state.majalisLoading = true;
            })
            .addCase(loadMajalis.fulfilled, (state, action) => {
                state.majalisLoading = false;
                state.isSuccess = true;
                state.majalis = action.payload;
            })
            .addCase(loadMajalis.rejected, (state, action) => {
                state.majalisLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadMajalisByPanrent.pending, (state) => {
                state.majalisByParentLoading = true;
            })
            .addCase(loadMajalisByPanrent.fulfilled, (state, action) => {
                state.majalisByParentLoading = false;
                state.isSuccess = true;
                state.majalisByParent = action.payload;
            })
            .addCase(loadMajalisByPanrent.rejected, (state, action) => {
                state.majalisByParentLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(loadRegions.pending, (state) => {
                state.regionLoading = true;
            })
            .addCase(loadRegions.fulfilled, (state, action) => {
                state.regionLoading = false;
                state.isSuccess = true;
                state.regions = action.payload;
            })
            .addCase(loadRegions.rejected, (state, action) => {
                state.regionLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
    },
});

export const {clearData} = calendarSlice.actions;

export default calendarSlice.reducer;
