import environments from "./environments";

let toast

export const setToast = (newToast) => {
    toast = newToast;
}

export const getToast = () => {
    return toast;
}

export const defaultErrorMessageToast = (toast, object) => {
    toast.current.show({
        severity: 'error',
        summary: 'Failed',
        sticky: true,
        detail: JSON.stringify(object),
        life: 3000
    });
}

export const defaultDebugMessageToast = (toast, object) => {
    if(environments.enableDebug)
    {
        toast.current.show({
            severity: 'info',
            summary: 'DEBUG',
            sticky: false,
            detail: JSON.stringify(object),
            life: 1000
        });
    }
        
}