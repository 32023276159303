import Events from "./Events/Events";
import EventTypes from "./Events/EventTypes/EventTypes";
import SecondPage from "./SecondPage/SecondPage";
import SecondSubPage from "./SecondPage/SecondSubPage/SecondSubPage";
import Dashboard from "./Dashboard/Dashboard";

const WEB_PAGES = {
  DASHBOARD: Dashboard,
  EVENTS: Events,
  EVENTS_TYPES: EventTypes,
  //NO_PAGE_FOUND: NoPageFound,
};
export default WEB_PAGES;
